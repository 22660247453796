import React, { useContext, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { 
    
    FormActions, RowDropInput, 
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import Drop from "components/Form/Drop";
import BooleanInput from "components/Form/BooleanInput";
import Input from "components/Form/Input";
import { toast } from "react-toastify";
import { exposeStrapiError, normalizeStrapiRegister, paycheckTypeOptions, paymentMethodOptions, statusOptions } from "utils";
import { Create, ReadAll, ReadOne, Update } from "services/requests";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { parseDatestringBRUS, parseDatestringUSBR } from "utils/parsers";
import { CoreContext } from "context/CoreContext";
import moment from "moment";
import { ContentTableAction } from "ui/styled";
import { Button } from "reactstrap";
import DashboardCardTable from "components/Dashboard/Cards/Table";


export default function DashboardFormsSolicitationRefund(){   

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { user, currentCompany, searchExpression } = useContext(CoreContext)
    
    const { id } = useParams()
    const refForm = useRef()
    const refFormResponse = useRef()

    const [ form, setForm ] = useState({ })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState({
        type:"refund",
        nome: user?.name,
        cpf: user?.cpf
    })
    const [saving, setSaving] = useState(false)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable: true }
    ]

    const formItems = [

        { placeholder:"Nome do funcionário", ref:"nome", half:true , disabled:true },
        { placeholder:"CPF", ref:"cpf", half:true, mask:"999.999.999-99", disabled:true  },
        { placeholder:"Tipo de solicitação", ref:"type", options:paycheckTypeOptions.slice(0,1) },
        { placeholder:"Data", ref:"date", mask:"99/99/9999" },
        { placeholder:"Valor", ref:"value", mask:"9999999" },
        { custom: <RowDropInput>
                <Drop disabled={!!id}  title={"Adicionar arquivo"} file={formValue('file')} onChange={e => changeForm(e, 'file')}  />
                <Input disabled={!!id} placeholder={"Obs. do funcionário"} type={"textarea"} value={formValue('reason')} onChange={e => changeForm(e.target.value, 'reason')} />
            </RowDropInput> , full:true },
    ]

    const formReviewItems = [
        { custom:<BooleanInput title={"Aprovar solicitação?"} value={formValue('isApprove')} onChange={e => changeForm(e, 'isApprove')} />, full:true },
        { placeholder:"Obs. do gerente (Opcional)", ref:"message", type:"textarea", full:true },
        { placeholder:"Data de pagamento (Opcional)", ref:"paymentDate", half:true, mask:"99/99/9999" },
        { placeholder:"Forma de pagamento (Opcional)", options: paymentMethodOptions, ref:"paymentMethod", half:true },
    ]

    const columns = [
        { title:'CPF', ref:'cpf' },
        { title:'Nome do funcionário', ref:'nome' },
        
        { title:'Data da solicitação', ref:'date' },
        
        { 
            title:'Status', 
            renderCell: ({ row }) => <>
                <ContentTableAction flat>
                    <Button color={row.status === 'Aprovado' ? 'green' : row.status === 'Recusado' ? 'red' : 'blue'} primary nospace small onClick={() => null}>
                        { row.status }
                    </Button>
                </ContentTableAction>
            </>
        },
        { title:'Data de pagamento', ref:'paymentDate' }
    ] 

    const [rows, setRows] = useState([ ])

    const init = async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
 
            const userRequests = await ReadAll(`filters[cpf]=${ normalResult?.cpf }`)
            if(userRequests?.data?.length){
                const usRqts = (userRequests?.data||[])?.filter(f => f?.attributes?.type === 'refund').map(m => normalizeStrapiRegister(m))?.map( m => ({
                    ...m,
                    status: statusOptions.find(f => f.id === m.status)?.title,
                    date: moment(m.createdAt)?.format('L'),
                    paymentDate: moment(m.startDate)?.format('L'), 
                    action:() => navigate(`dashboard/solicitation/refund/${ m.id }`)
                }))
                setRows(usRqts)
                console.log('userRequests', usRqts )
            }

            setRegister({
                ...normalResult,
                answers: normalResult?.answers?.map(m => ({ ...m, paymentDate: parseDatestringUSBR(m.paymentDate) }) ),
                date: normalResult?.startDate ? parseDatestringUSBR(normalResult.startDate) : "",
            })
            const nfile = normalResult?.file?.id ? normalResult?.file : null
            setForm({ ...form, ...normalResult?.answers?.[0], reason: normalResult?.reason, file: nfile })
        }
        setLoading(false)
    }

    const save = async () => {
        const nform = { ...refForm.current.getForm() }
        const aform = !id || !user?.isMAnager ? null : { ...refFormResponse.current.getForm() }

        const answers = !id || !user?.isMAnager ? [] : [
            {
                ...aform,
                isApprove: form.isApprove,
                paymentDate: parseDatestringBRUS(aform?.paymentDate)
            }
        ]

        const payload = {
            ...nform,
            startDate: parseDatestringBRUS(nform?.date),
            answers,
            user: user?.id,
            file: form?.file?.id,
            reason: form.reason,
            company: currentCompany?.id,
        }

        if(!valid(payload, formItems)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const valid = (payload, array) => {  
        for(let item of array){
            if(item?.ref && !payload?.[item?.ref]){
                return false;
            }
        }
        return true
    } 
    
    useEffect(() => {
        if(id){
            init()
        }
    }, [id])

    return ( 
        <>
            <DashboardCardWrapper backable title={"Detalhe da solicitação"} white loading={saving}>
                {/* <DashboardFeedback message={"informe de rendimento cadastrado com sucesso"} type={"success"} /> */}
                <DashboardFormsCore disabled={!!id} formItems={formItems} register={register} ref={refForm} />
                {
                    !id || !user?.isMAnager ? null :
                    <DashboardCardWrapper title={"Responder a solicitação"} white>
                        <DashboardFormsCore formItems={formReviewItems} ref={refFormResponse} register={register?.answers?.[0]} />
                    </DashboardCardWrapper>
                }
                <DashboardCardTable title={"Timeline"} rows={rows} columns={columns} searchExpression={searchExpression} loading={loading} />
                <FormActions>
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions>                                
            </DashboardCardWrapper>
        </>
    );
}