import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


export const ConfirmationDialog = ({ open, title, message, onConfirm, onCancel, children, btnCancelText, btnConfirmText }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {btnCancelText}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {btnConfirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}