import { useContext } from "react";
import DashboardCardTable from "../Table";
import { CoreContext } from "context/CoreContext";
import moment from "moment";
import CheckIcon from '@mui/icons-material/Check';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import CircularProgress from '@mui/material/CircularProgress';
import DashboardCardWrapper from "../Wrapper";
import { LoadingContainer, NoDataContainer, NoDataIcon, NoDataText } from "../styled";
import { CardContainer } from "../styled";

export default function DashboardCardLastSignature({ register, loading, filterMonth }) {   
    const { searchExpression } = useContext(CoreContext);

    const rows = register?.holeriteSignatures
        ?.filter(hol => {
            if (filterMonth) {
                const signatureMonth = moment(hol.signedAt).month() + 1;
                return hol.isSigned && signatureMonth === parseInt(filterMonth);
            }
            return hol.isSigned;
        })
        .sort((a, b) => moment(b.signedAt).valueOf() - moment(a.signedAt).valueOf())
        .slice(0, 5)
        .map(hol => ({
            name: hol.name,
            isSigned: <CheckIcon style={{ color: 'green' }} />,
            signedAt: moment(hol.signedAt).format("DD/MM/YYYY HH:mm"),
        })) || [];

    const columns = [
        { title: 'Nome do funcionário', ref: 'name' },
        { title: 'Assinado', ref: 'isSigned' },
        { title: 'Data e Hora da Assinatura', ref: 'signedAt' }
    ];

    // Verificar se tem dados após a filtragem
    const hasData = rows.length > 0;

    if (loading) {
        return (
            <CardContainer>
                <DashboardCardWrapper title="Assinaturas">
                    <LoadingContainer>
                        <CircularProgress size={40} />
                    </LoadingContainer>
                </DashboardCardWrapper>
            </CardContainer>
        );
    }

    if (!hasData) {
        return (
            <CardContainer>
                <DashboardCardWrapper title="Assinaturas">
                    <NoDataContainer>
                        <NoDataIcon>
                            <InsertChartIcon />
                        </NoDataIcon>
                        <NoDataText>
                            Nenhum registro encontrado
                        </NoDataText>
                    </NoDataContainer>
                </DashboardCardWrapper>
            </CardContainer>
        );
    }

    return (
        <CardContainer>
            <DashboardCardTable 
                title="Assinaturas" 
                rows={rows} 
                columns={columns} 
                searchExpression={searchExpression} 
            />
        </CardContainer>
    );
}


