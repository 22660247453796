import styled from 'styled-components';

export const LoadingContainer = styled.div`
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NoDataContainer = styled.div`
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666;
    gap: 12px;
`;

export const NoDataIcon = styled.div`
    svg {
        font-size: 64px;
        color: #999;
        opacity: 0.5;
    }
`;

export const NoDataText = styled.span`
    font-size: 18px;
    font-weight: 600;
    color: #666;
`;

export const CardContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    & > div {
        height: 100%;
        display: flex;
        flex-direction: column;

        & > div:last-child {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }

    .google-visualization-chart-container {
        height: 100% !important;
    }
`; 