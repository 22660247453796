import styled from "styled-components";

export const CompanyDataContainer = styled.div.attrs({
})`

`;

export const CloseContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;