import React, { useState, createContext, useEffect } from 'react'
import { ReadObject, SaveObject } from '../services/storage'

export const CoreContext = createContext({})

export const CoreState = ({ children }) => {

	const [user, setUser] = useState(ReadObject('user') ? ReadObject('user') : [])
	const [searchExpression, setSearchExpression] = useState('')
	const [currentCompany, setCurrentCompany] = useState(null)
	const [companies, setCompanies] = useState([])
	const [modal, setModal] = useState(null)


	const contextValue = {
		user, setUser,
		searchExpression, setSearchExpression,
		currentCompany, setCurrentCompany,
		companies, setCompanies,
		modal, setModal
	}

	// to persist state when app reload  
	useEffect(() => { SaveObject('user', user); }, [user])

	return <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
}
