import React from "react";  

import {  
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import DashboardListsVacantion from "components/Dashboard/Lists/Vacantion";

export default function DashboardListVacantion(){  

    return ( 
        <>
            <ContainerAuthenticated page={"vacantion"}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <DashboardListsVacantion />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}