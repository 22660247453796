import React from "react";  

import {  
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import DashboardListsEvents from "components/Dashboard/Lists/Events";

export default function DashboardListEvents(){  

    return ( 
        <>
            <ContainerAuthenticated page={"events"}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <DashboardListsEvents />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}