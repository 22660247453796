import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/report-incomes`, params, true);
}
export const CreateAllImports = async (params) => {
    return await POST(`/import-incomes`, params, true);
}

export const ReadAll = async (id) => {
    return await GET(`/report-incomes?filters[company]=${id}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadAllByUploadId = async (uploadId) => {
    return await GET(`/report-incomes?filters[uploadId]=${uploadId}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadAllForEmployee = async (id) => {
    return await GET(`/report-incomes?filters[employee]=${id}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadAllImports = async (id) => {
    return await GET(`/import-incomes?&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadRIncomes = async (id, cpf, exercise, year, employee) => {
    return await GET(`/report-incomes?filters[company]=${id}&filters[cpf]=${cpf}&filters[exercise]=${exercise}&filters[year]=${year}&filters[employee]=${employee}`, true);
}


export const Read = async (id) => {
    return await GET(`/report-incomes?filters[employee]=${id}&populate=employee`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/report-incomes/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/report-incomes/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/report-incomes/${id}`, true);
}

export const GeneratePdf = async (ids) => {
    return await POST(`/report-incomes/generate-pdf`, { ids }, true, true);
}
