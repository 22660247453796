import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/employees`, params, true);
}

export const Read = async (companyId) => {
    return await GET(`/employees?filters[companies][id][$eq]=${companyId}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadEmployeeByUserId = async (userId) => {
    return await GET(`/employees?filters[user][id][$eq]=${userId}&pagination[pageSize]=1000`, true);
}

export const ReadEmployee = async (query) => {
    return await GET(`/employees${query}`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/employees/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/employees/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/employees/${id}`, true);
}
