import React, { useContext, useEffect, useState } from "react";  

import {

} from "./styled";


import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove } from "ui/styled";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { Delete, Read } from "services/users";
import { toast } from "react-toastify";
import { parseCurrency } from "utils/parsers";

export default function DashboardListsUsers(){  
    
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const [activeTab, setActiveTab] = useState(0)
    const { searchExpression, currentCompany } = useContext(CoreContext)

    const options = [
        { id:1, title:"Ver" }
    ]

    const tabs = [  ]

    const actions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Novo', action:() => navigate("dashboard/users/add") }
    ]

    const columns = [
        { title:'CPF', ref:'document' },
        { title:'Nome do funcionário', ref:'name' },
        { title:'Email', ref:'email' },
        { 
            title:' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <Button color={'green'} primary nospace small onClick={() => navigate(`dashboard/users/add/${row.id}`)}>
                        <ContentTableActionIconRemove />
                    </Button>
                    <Button color={'red'} primary nospace small onClick={() => remove(row.id)}>
                        <ContentTableActionIconEdit />
                    </Button>
                </ContentTableAction>
            </>
        }
    ] 

    const [rows, setRows] = useState([ ])

    const [loading, setLoading] = useState(false)
    
    const parseRegister = item => ({ 
        ...item,
        document: item.cpf || 'Sem dados', 
        name: item.username || 'Sem dados',
        email: item.email || 'Sem dados',
    })

    const init = async () => {
        console.log("currentCompany", currentCompany)
        setLoading(true)
        const result = await Read(currentCompany?.id)
        if(result && !exposeStrapiError(result)){
            const normalRegister = (result) || []

            // console.log("normalRegister", normalRegister)
            setRows(normalRegister.map(parseRegister))
        }
        setLoading(false)
    }

    const remove = async id => {
        setLoading(true)
        const result = await Delete(id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        init()
    }

    useEffect(() => {
        if(currentCompany?.id){ init() ;}
    }, [currentCompany])

    return ( 
        <>
            <DashboardCardTabs title={"Usuários cadastrados"} actions={actions} tabs={tabs} active={activeTab} setActive={setActiveTab} selectable={{ options, placeholder:"Ações" }} >
                { activeTab === 0 ? <DashboardCardTable rows={rows} columns={columns} searchExpression={searchExpression} pagination loading={loading} /> : null }
            </DashboardCardTabs>
        </>
    );
}