import React, { useEffect, useState } from "react";  

import { 
    ContentActions,
    ContentActionsIconAdd,
    ContentActionsIconRemove,
    ContentAddable,
    ContentRow,
    ContentRowAction,
    ContentRowInput
} from "./styled";

import Button from "components/Form/Button";
import Input, { MaskedInput } from "components/Form/Input";
import { toast } from "react-toastify";
import { numerize } from "utils";

export default function MultiInput({label, placeholder, value, onChange, period, balance}){   
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const [begging, setBegging] = useState(true)
    const [codes, setCodes] = useState([])
    
    const addCode = () => {
        setCodes([ ...codes, { id: ((new Date()).getTime()) }])
    }
    
    const removeCode = (item) => {
        setCodes([ ...codes.filter( f => f.id !== item.id ) ])
    }

    const handleChange = () => {
        if(typeof onChange === 'function'){
            onChange(codes.map(m => period ? { 
                dateBegin: (form?.[`dateBegin${m.id}`] || ""), 
                dateEnd: (form?.[`dateEnd${m.id}`] || ""),  
                dateDays: (form?.[`dateDays${m.id}`] || "")
            } : form?.[m.id] || "" ))
        }
    }

    const handleValue = () => {
        if(value?.length){
            const nextCodes = value.map((m,k) => ({ id: period ? `${k}` : `input${k}` }))
            const nextForm = value.reduce((p, c, k) => { 
                if(period){
                    p[`dateBegin${k}`] = c.dateBegin ; 
                    p[`dateEnd${k}`] = c.dateEnd ; 
                    p[`dateDays${k}`] = c.dateDays ; 
                } else {
                    p[`input${k}`] = c ; 
                }
                return p ; 
            }, {})
            setCodes(nextCodes)
            setForm(nextForm)
        }
    }

    const validateVacation = (id) => {
        if( formValue(`dateBegin${id}`) && formValue(`dateDays${id}`) ){
            if( parseInt(formValue(`dateDays${id}`)) > parseInt(balance || "0") ){
                toast.error("Quantidade de dias maior que saldo");
                changeForm('', `dateDays${id}`)
                return;
            }

            const bDates = formValue(`dateBegin${id}`)?.split('/')
            const vacationBegins = new Date(bDates[2], bDates[1]-1, bDates[0])
            vacationBegins.setDate(vacationBegins.getDate() + parseInt(formValue(`dateDays${id}`)))
            const returnDate = `${numerize(vacationBegins.getDate())}/${numerize(vacationBegins.getMonth()+1)}/${vacationBegins.getFullYear()}`
            
            changeForm(returnDate, `dateEnd${id}`)
        }
    }

    useEffect(() => { if(!begging){ handleChange() ;} }, [form])
    useEffect(() => { if(begging){ handleValue() ;} }, [value])
    useEffect(() => { setBegging(false) ;}, [])

    return ( 
        <>
            <ContentAddable>
                {
                    codes?.map((item, key ) => 
                        <ContentRow key={key}>
                            {
                                period ? <>
                                    <ContentRowInput>
                                        <MaskedInput mask="99/99/9999" placeholder={ "Data de inicio" } value={formValue(`dateBegin${item.id}`)} onChange={e => changeForm(e.target.value, `dateBegin${item.id}`)} onBlur={() => validateVacation(item.id)} />
                                    </ContentRowInput>
                                    <ContentRowInput>
                                        <MaskedInput mask="99/99/9999" disabled placeholder={ "Data final" } value={formValue(`dateEnd${item.id}`)} onChange={e => changeForm(e.target.value, `dateEnd${item.id}`)} />
                                    </ContentRowInput>
                                    <ContentRowInput>
                                        <MaskedInput mask="999" placeholder={ "Dias" } value={formValue(`dateDays${item.id}`)} onChange={e => changeForm(e.target.value, `dateDays${item.id}`)} onBlur={() => validateVacation(item.id)} />
                                    </ContentRowInput>
                                </> : 
                                <ContentRowInput>
                                    <Input placeholder={ placeholder } value={formValue(item.id)} onChange={e => changeForm(e.target.value, item.id)} />
                                </ContentRowInput>
                            }
                            <ContentRowAction>
                                <Button big primary outline nospace onClick={() => removeCode(item)}>
                                    <ContentActionsIconRemove /> 
                                </Button>
                            </ContentRowAction>
                        </ContentRow>
                    )
                }
                <ContentActions>
                    <Button nospace primary outline onClick={addCode}>
                        <ContentActionsIconAdd />
                        { label }
                    </Button>
                </ContentActions>
            </ContentAddable>
        </>
    );
}