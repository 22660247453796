import React from "react";  

import {  
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import DashboardListsEmployee from "components/Dashboard/Lists/Employee";

export default function DashboardListEmployee(){  

    return ( 
        <>
            <ContainerAuthenticated page={"employee"}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <DashboardListsEmployee />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}