import React, { useContext, useEffect, useState } from "react";  

import {

} from "./styled";


import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove } from "ui/styled";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { parseDatestringUSBR } from "utils/parsers";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { Delete, Read } from "services/holidays";
import { toast } from "react-toastify";

export default function DashboardListsHolidays(){  
    
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const [activeTab, setActiveTab] = useState(0)
    const { searchExpression, currentCompany, user } = useContext(CoreContext)

    const options = [
        { id:1, title:"Ver" }
    ]

    const tabs = [  ]

    const actions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        !user?.isManager ? null : { title:'Novo', action:() => navigate("dashboard/holidays/add") }
    ].filter(f => f)

    const columns = [
        { title:'Nome do feriado', ref:'name' },
        { title:'Data do feriado', ref:'date' },
        !user?.isManager ? null : { 
            title:' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <Button color={'green'} primary nospace small onClick={() => navigate(`dashboard/holidays/add/${ row.id }`)}>
                        <ContentTableActionIconRemove />
                    </Button>
                    <Button color={'red'} primary nospace small onClick={() => remove(row.id)}>
                        <ContentTableActionIconEdit />
                    </Button>
                </ContentTableAction>
            </>
        }
    ].filter(f => f)

    const [rows, setRows] = useState([  ])

    const [loading, setLoading] = useState(false)
    
    const parseRegister = item => ({ 
        ...item,
        name: item.name, 
        date: parseDatestringUSBR(item?.date)
    })

    const init = async () => {
        setLoading(true)
        const result = await Read(currentCompany?.id)
        if(result && !exposeStrapiError(result)){
            const normalRegister = normalizeStrapiList(result) || []
            setRows(normalRegister.map(parseRegister))
        }
        setLoading(false)
    }

    const remove = async id => {
        setLoading(true)
        const result = await Delete(id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        init()
    }

    useEffect(() => {
        if(currentCompany?.id){ init() ;}
    }, [currentCompany])

    return ( 
        <>
            <DashboardCardTabs title={"Cadastro de feriados"} actions={actions} tabs={tabs} active={activeTab} setActive={setActiveTab} selectable={{ options, placeholder:"Ações" }} >
                { activeTab === 0 ? <DashboardCardTable rows={rows} columns={columns} searchExpression={searchExpression} loading={loading} /> : null }
            </DashboardCardTabs>
        </>
    );
}