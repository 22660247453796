import React from "react";  

import { 
    HeaderStatusItem, RowInputs
} from "./styled";
import Radio from "components/Form/Radio";

export default function BooleanInput({ title, options, value, onChange }){   
    
    return ( 
        <> 
            <HeaderStatusItem>
                <b>{ title }</b>
                {
                    onChange ? 
                        <RowInputs>
                            <Radio checked={value} label="Sim" onChange={ () => onChange(true) } />
                            <Radio checked={!value} label="Não" onChange={ () => onChange(false) } />
                        </RowInputs>
                    : <p>{ !!value ? 'Sim' : 'Não' }</p>
                }
            </HeaderStatusItem> 
        </>
    );
}