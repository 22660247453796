import React, { useContext, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { 
    
    FormActions, 
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import { Create, ReadOne, Update } from "services/holidays";
import { exposeStrapiError, normalizeStrapiRegister, repeatOptions } from "utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { parseDatestringBRUS, parseDatestringUSBR } from "utils/parsers";
import { CoreContext } from "context/CoreContext";


export default function DashboardFormsHoliday(){   

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()
    const refForm = useRef()
    const { currentCompany } = useContext(CoreContext)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable: true }
    ]

    const formItems = [
        { placeholder:"Nome do feriado", ref:"name" },
        { placeholder:"Data do feriado", ref:"date", mask:"99/99/9999" },
        { placeholder:"Recorrente", options:repeatOptions, ref:"repeat" },
    ]
    
    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState(null)
    const [saving, setSaving] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            setRegister({
                ...normalResult,
                date: parseDatestringUSBR(normalResult?.date)
            })
        }
        setLoading(false)
    }

    const save = async () => {
        const form = refForm.current.getForm()
        const payload = {
            ...form,
            company: currentCompany?.id,
            date: parseDatestringBRUS(form?.date)
        }

        if(!valid(payload, formItems)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const valid = (payload, array) => {  
        for(let item of array){
            if(!payload?.[item?.ref]){
                return false;
            }
        }
        return true
    } 
    
    useEffect(() => {
        if(id){
            init()
        }
    }, [id])

    return ( 
        <>
            <DashboardCardWrapper backable title={"Novo feriado"} white loading={saving}>
                {/* <DashboardFeedback message={"informe de rendimento cadastrado com sucesso"} type={"success"} /> */}
                <DashboardFormsCore formItems={formItems} register={register} ref={refForm} />
                <FormActions>
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions>                                
            </DashboardCardWrapper>
        </>
    );
}