import React from "react";  

import {  
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import DashboardListsRevenues from "components/Dashboard/Lists/Revenues";

export default function DashboardListRevenues(){  

    return ( 
        <>
            <ContainerAuthenticated page={"revenues"}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <DashboardListsRevenues />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}