import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/holidays`, params, true);
}

export const Read = async (id) => {
    return await GET(`/holidays?filters[company]=${id}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/holidays/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/holidays/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/holidays/${ id }`, true);
} 
 