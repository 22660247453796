import React from "react";  

import {  
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import DashboardFormsEmployee from "components/Dashboard/Forms/Employee";

export default function DashboardFormEmployee(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    return ( 
        <>
            <ContainerAuthenticated page={"employee"}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <DashboardFormsEmployee />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}