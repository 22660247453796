import React, { useContext, useEffect, useState } from "react";  

import {

} from "./styled";


import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove } from "ui/styled";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { parseDatestringUSBR } from "utils/parsers";
import { Read } from "services/vacations";
import { exposeStrapiError, normalizeStrapiList } from "utils";

export default function DashboardListsVacantion(){  
    
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const [activeTab, setActiveTab] = useState(0)
    const { searchExpression, currentCompany, user } = useContext(CoreContext)

    const options = [
        { id:1, title:"Ver" }
    ]

    const tabs = [  ]

    const actions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        // { title:'Novo', action:() => navigate("dashboard/vacantion/add") }
    ]

    const columns = [
        { title:'CPF', ref:'cpf' },
        { title:'Nome do funcionário', ref:'name' },
        { title:'Período aquisitivo', ref:'period' },
        { title:'Dias disponíveis', ref:'daysAvailable' },
        { title:'Dias utilizados', ref:'daysUsed' },
        { title:'Vencimento das férias', ref:'limit' },
        { title:'Saldo de dias', ref:'daysAmount' }
    ] 

    const [rows, setRows] = useState([ ])


    const [loading, setLoading] = useState(false)
    
    const parseRegister = item => ({ 
        ...item,
        name: item.name, 
        date: parseDatestringUSBR(item?.date),

        cpf: item.cpf, 
        name: item.name, 
        period: item?.scheduling?.[0]?.initial ? `${ parseDatestringUSBR(item?.scheduling?.[0]?.initial) } - ${ parseDatestringUSBR(item?.scheduling?.[0]?.end) }` : ``, 
        daysAvailable: item.availableDays, 
        daysUsed: item.usedDays, 
        daysAmount: item.balanceDays,
        limit: item.limit,
        action: !user?.isManager ? null : () => navigate(`dashboard/vacantion/add/${ item.id }`)
    })

    const init = async () => {
        setLoading(true)
        const result = await Read(currentCompany?.id)
        if(result && !exposeStrapiError(result)){
            const normalRegister = normalizeStrapiList(result) || []
            setRows(normalRegister?.filter( f => user?.isManager || f?.cpf === user?.cpf )?.map(parseRegister))
        }
        setLoading(false)
    }

    useEffect(() => {
        if(currentCompany?.id){ init() ;}
    }, [currentCompany])

    return ( 
        <>
            <DashboardCardTabs title={"Férias"} actions={actions} tabs={tabs} active={activeTab} setActive={setActiveTab} selectable={{ options, placeholder:"Ações" }} >
                { activeTab === 0 ? <DashboardCardTable rows={rows.sort((a,b) => a.limit - b.limit).map(m => ({...m, limit: parseDatestringUSBR(m.limit) }))} columns={columns} searchExpression={searchExpression} loading={loading} /> : null }
            </DashboardCardTabs>
        </>
    );
}