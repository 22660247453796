import React from "react";  

import {  
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import DashboardListsLogs from "components/Dashboard/Lists/Logs";

export default function DashboardListLogs(){  

    return ( 
        <>
            <ContainerAuthenticated page={"logs"}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <DashboardListsLogs />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}