import styled from 'styled-components'  

export const Content = styled.div.attrs({ 
})`
`;

export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
`;


export const RowDropInput = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
`;


