import { useContext, useState } from "react";

import { useHistory } from 'react-router-dom';

import {

    DashboardMenuContainer,
    DashboardMenu, DashboardMenuOption,
    DashboardMenuContent, DashboardLogo,
    DashboardLogoContainer,
    DashboardMenuOptionIconDecoration,
    DashboardMenuOptionIcon,
    DashboardMenuOptionStatus,
    DashBoarMenuOptionWrap
} from "./styled";

import { DoLogout } from "services/authentication";
import { CoreContext } from "context/CoreContext";
import { Version } from "./styled";
import { getVersion } from 'config/version';

export default function DashboardSide({ opened, setOpened, page }) {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { user, currentCompany } = useContext(CoreContext)

    const verifyClose = e => {
        if (!e.target.closest('.menu-contant')) {
            // setOpened(false)
        }
    }

    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    const [activeSide, setActiveSide] = useState(2)

    const sideOptions = [
        { ref: 'home', title: "Home", icon: "1", action: () => navigate('dashboard') },
        { ref: 'paycheck', title: "Holerites", icon: "2", action: () => navigate('dashboard/paycheck') },
        { ref: 'revenues', title: "Informe de rendimentos", icon: "3", action: () => navigate('dashboard/revenues') },
        !user?.isManager ? null : { ref: 'employee', title: "Funcionários", icon: "6", action: () => navigate('dashboard/employee') },
        !user?.isManager ? null : { ref: 'users', title: "Usuários", icon: "7", action: () => navigate('dashboard/users') },
        !user?.isManager ? null : { ref: 'events', title: "Eventos", icon: "8", status: "Em Desenvolvimento" },
        !user?.isManager ? null : { ref: 'logs', title: "Log de atividades", icon: "10", action: () => navigate('dashboard/logs')},
        // { ref: 'solicitation', title: "Solicitações", icon: "4", action: () => navigate('dashboard/solicitation'), status: "Em Desenvolvimento" },
        // { ref: 'vacantion', title: "Férias", icon: "5", action: () => navigate('dashboard/vacantion'), status: "Em Desenvolvimento" },
        // user?.isManager ? { ref: 'holidays', title: "Cadastro de Feriados", icon: "9", action: () => navigate('dashboard/holidays'), status: "Em Desenvolvimento" } : { ref: 'holidays', title: "Feriados", icon: "9", action: () => navigate('dashboard/holidays'), status: "Em Desenvolvimento" },
        !user?.isManager || !currentCompany?.urlLooker ? null : { ref: 'looker', title: "Looker Studio", icon: "10", action: () => navigate('dashboard/looker-studio'), status: "Em Desenvolvimento" },
        { ref: 10, title: "Sair", icon: "11", action: exit },
    ].filter(f => f)

    const version = getVersion();

    return (
        <>
            {
                !opened ? null :
                    <DashboardMenuContainer onClick={verifyClose}>
                        <DashboardMenu>
                            <DashboardLogoContainer>
                                <DashboardLogo src={`/images/logo.png`} alt="logo-icon" />
                            </DashboardLogoContainer>

                            <DashboardMenuContent>
                                {
                                    sideOptions.map((item, key) =>
                                        <DashboardMenuOption key={key} active={page === item.ref} onClick={item.action}>
                                            <DashboardMenuOptionIconDecoration active={page === item.ref}>
                                                <DashboardMenuOptionIcon active={page === item.ref} icon={item.icon} />
                                            </DashboardMenuOptionIconDecoration>

                                            <DashBoarMenuOptionWrap>
                                                {item.title}
                                                <DashboardMenuOptionStatus active={page === item.ref}>{item.status || ''}</DashboardMenuOptionStatus>
                                            </DashBoarMenuOptionWrap>
                                        </DashboardMenuOption>
                                    )
                                }
                            </DashboardMenuContent>

                            <Version>
                                Versão {version.full}
                            </Version>
                        </DashboardMenu>
                    </DashboardMenuContainer>
            }
        </>
    );
}