import styled from 'styled-components'  


export const CardHomeHeader = styled.div.attrs({ 
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid ${ props => props.theme.palette.colors.lightshadow };
    flex-wrap:wrap;
    ${
        props => props.flat ? `
            padding: 0 0 12px;
        ` : ``
    }
`;

export const CardHomeHeaderActions = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 180px;
`;



export const HeaderTabs = styled.div.attrs({ 
})`
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    gap: 16px;
    margin-bottom: -12px;
`;
export const HeaderTabItem = styled.div.attrs({ 
})`
    font-size: 16px;
    color: ${ props => props.theme.palette.colors.lightgrey }; 
    display:flex;
    align-items: center;
    gap: 6px;
    padding: 12px 0;
    ${ props => props.active ? `
        color: ${ props.theme.palette.primary.main };
        border-bottom: 1px solid ${ props.theme.palette.primary.main };
    ` : `` }; 

    cursor:pointer;
    &:hover{

    }
`;
export const HeaderTabItemBadge = styled.div.attrs({ 
})`
    padding: 0 4px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.error.main }; 
    color: ${ props => props.theme.palette.colors.white }; 
    font-size: 12px;
`;
export const HeaderTabsFilter = styled.div.attrs({ 
})`
    display: flex; 
    gap: 72px;
    flex:1;
    align-items: center;
`;

export const HeaderTabsTitle = styled.div.attrs({ 
})`
    font-size:18px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.text }; 
`;