import styled from 'styled-components'  

export const Content = styled.div.attrs({ 
})`
`;




export const ContentAddable = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0 0;
`;

export const ContentRow = styled.div.attrs({ 
})`
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    padding: 0 0 12px 0;
`;
export const ContentRowInput = styled.div.attrs({ 
})`
    flex:1;
`;

export const ContentRowAction = styled.div.attrs({ 
})`
    max-width: 70px;
    width: 100%;
`;

export const ContentActions = styled.div.attrs({ 
})`
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
`;

export const ContentActionsIconAdd = styled.img.attrs({ 
    src:'/icons/add.svg'
})`
`;

export const ContentActionsIconRemove = styled.img.attrs({ 
    src:'/icons/remove.svg'
})`
`;
