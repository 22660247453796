import { useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle, Wrapper
} from './styled';

import Button from "components/Form/Button";
import Input, { MaskedInput } from 'components/Form/Input';

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoFinishFirstAccess } from "services/authentication";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function FinishedFirstAccess() {
    const history = useHistory();
    const navigate = (to) => history.push(`/${to}`);

    const { sessionId } = useParams();

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});

    const formValue = ref => form?.[ref] ? form?.[ref] : '';
    const changeForm = (value, ref) => setForm({ ...form, [ref]: value });

    const valid = (verbose = false) => {
        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error('Preencha o campo: Senha') };
            return false;
        }

        if (formValue('password') !== formValue('confirmPassword')) {
            if (verbose) { toast.error('As senhas não coincidem') };
            return false;
        }

        if (!formValue('cpf') || !formValue('cpf').length) {
            if (verbose) { toast.error('Preencha o campo: CPF') };
            return false;
        }

        if (!formValue('email') || !formValue('email').length) {
            if (verbose) { toast.error('Preencha o campo: Email') };
            return false;
        }

        if (!formValue('telefone') || !formValue('telefone').length) {
            if (verbose) { toast.error('Preencha o campo: Telefone') };
            return false;
        }

        return true;
    }

    const action = async () => {
        try {
            if (!valid(true)) { return; }
            setLoading(true);

            const formattedPhone = formValue('telefone').replace(/\D/g, '');
            const formattedCPF = formValue('cpf').replace(/\D/g, '');

            const result = await DoFinishFirstAccess({
                sessionId,
                password: formValue('password'),
                cpf: formattedCPF,
                email: formValue('email'),
                phone: formattedPhone
            });

            setLoading(false);

            if (result?.message === 'User successfully created!') {
                toast.success("Primeiro acesso finalizado com sucesso!");
                toast.info("Para continuar, você precisa fazer login.");
                navigate('login');
            } else if (result?.error) {
                toast.error(result.error.message || "Erro ao tentar finalizar o primeiro acesso.");
            } else {
                toast.error("Erro ao tentar finalizar o primeiro acesso.");
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
            toast.error("Erro ao tentar finalizar o primeiro acesso.");
        }
    }

    return (
        <>
            <ContainerUnauthenticated>
                <FormTitle>Finalizar Primeiro Acesso</FormTitle>
                <Wrapper>
                    <Input type="password" placeholder="Digite sua senha" id={'password'} value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />
                    <Input type="password" placeholder="Confirme sua senha" id={'confirmPassword'} value={formValue('confirmPassword')} onChange={e => changeForm(e.target.value, 'confirmPassword')} />
                    <MaskedInput mask="999.999.999-99" placeholder="CPF (Somente números)" id={'cpf'} value={formValue('cpf')} onChange={e => changeForm(e.target.value, 'cpf')} />
                    <Input placeholder="Email" id={'email'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} />
                    <MaskedInput mask="(99) 99999-9999" placeholder="Telefone (Somente números)" id={'telefone'} value={formValue('telefone')} onChange={e => changeForm(e.target.value, 'telefone')} />
                </Wrapper>
                <Button primary loading={loading} onClick={action}>Continuar</Button>
                <Button primary outline onClick={() => history.goBack()}>Voltar</Button>
            </ContainerUnauthenticated>
        </>
    );
}
