import React, { useContext, useEffect, useState } from "react";

import {

} from "./styled";


import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, paycheckTypeOptions, typePaycheckOptions, typeSheetOptions } from "utils";
import { GeneratePdf, Read, ReadAll, ReadAllByUploadId, ReadAllForEmployee, ReadAllImports, ReadHoleritesGrouped, SignHolerite } from "services/holerites";
import { parseDatestringUSBR } from "utils/parsers";
import Accordion from "components/Accordion";
import BasicTable from "components/Form/Table";
import moment from "moment";
import { ReadEmployeeByUserId } from "services/employee.js";
import { toast } from "react-toastify";
import DashboardActions from "components/Dashboard/Actions";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


export default function DashboardListPaycheck() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [activeTab, setActiveTab] = useState(0);
    const [uploadsRegister, setUploadsRegister] = useState([]);
    const { searchExpression, currentCompany, user, setSearchExpression } = useContext(CoreContext);
    const [rows, setRows] = useState([]);
    console.log("🚀 ~ DashboardListPaycheck ~ rows:", rows[0])
    const [loading, setLoading] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);

    const options = [
        { id: 1, title: "Ver" }
    ];

    const tabs = [
        { title: 'Cadastros' },
        // { title:'Erros', badge:2 }
    ];

    const actionsSingle = [
        ...(user?.isManager ? [{
            title: "Download em LOTE",
            action: (uploadId) => downloadMassByUploadId(uploadId),
            loadable: true
        }] : []),
        ...(!user?.isManager ? [{
            title: "Assinar",
            action: (registerId) => downloadSingle(registerId),
            loadable: true
        }] : [])
    ];


    const downloadMassByUploadId = async (uploadId) => {
        try {
            const result = await ReadAllByUploadId(uploadId);
            const ids = result.data.map(row => row.id);

            if (ids.length > 0) {
                const blob = await GeneratePdf(ids);
                if (blob) {
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `holerites_${uploadId}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } else {
                    throw new Error('Nenhum blob válido recebido para download.');
                }
            } else {
                toast.error("Nenhum registro encontrado para download.");
            }
        } catch (error) {
            console.error('Erro ao baixar os registros:', error);
            toast.error("Erro ao gerar o PDF: " + error.message);
        }
    };

    const downloadSingle = async (registerId) => {
        const userId = user?.id;
        
        const currentHolerite = rows.find(row => row.id === registerId);
        const competence = `${currentHolerite?.month}/${currentHolerite?.year}`;

        const response = await ReadEmployeeByUserId(userId);
        const employeeId = response.data[0]?.id;

        if (!employeeId || !competence) {
            toast.error("Erro: Não foi possível determinar o ID do usuário ou a competência.");
            return;
        }

        const holeriteSigned = currentHolerite?.isSigned;

        if (!holeriteSigned) {
            try {
                const response = await SignHolerite(employeeId, competence);
                console.log("🚀 ~ downloadSingle ~ response:", response);

                const updatedRows = rows.map(row => {
                    if (row.id === registerId) {
                        return {
                            ...row,
                            isSigned: true,
                            signedAt: moment().format("DD/MM/YYYY HH:mm")
                        };
                    }
                    return row;
                });

                setRows(updatedRows);
                toast.success("Holerite assinado com sucesso!");
            } catch (error) {
                console.error("Erro ao assinar o holerite:", error);
                toast.error("Erro ao assinar o holerite: " + error.message);
                return;
            }
        }

        try {
            const blob = await GeneratePdf(registerId);
            if (blob) {
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'holerites.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                throw new Error('No valid blob received for download.');
            }
        } catch (error) {
            console.error('Error downloading the PDF:', error);
            toast.error("Erro ao gerar o PDF: " + error.message);
        }
    };


    const actions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        ...(user?.isManager ? [{ title: 'Novo', action: () => navigate("dashboard/paycheck/add") }] : [])
    ];

    const getActionsForEmployee = (isSigned) => {
        if (!isSigned) {
            return [
                {
                    title: "Visualizar e Assinar",
                    action: (registerId) => downloadSingle(registerId),
                    loadable: true
                }
            ];
        } else {
            return [
                {
                    title: "Visualizar",
                    action: (registerId) => downloadSingle(registerId),
                    loadable: true
                }
            ];
        }
    };

    const employeeColumns = [
        { title: 'Ano', ref: 'year' },
        { title: 'Mês', ref: 'month' },
        { title: 'Tipo de holerite', ref: 'type' },
        { title: 'Registro', ref: 'reg' },
        { title: 'Nome do funcionário', ref: 'name' },
        { title: 'CPF', ref: 'cpf' },
        { title: 'Liquido', ref: 'liquid' },
        { title: 'Data e hora da assinatura', ref: 'signedAt' },
        {
            title: "Assinaturas",
            ref: "isSigned",
            renderCell: (row) => {
                const isSigned = row.row.isSigned === true;
                return !isSigned ? <CloseIcon style={{ color: 'red' }} /> : <CheckIcon style={{ color: 'green' }} />;
            }
        },
        {
            title: "", ref: "",
            renderCell: (row) => {
                const isSigned = row.row.isSigned === true;

                const updatedActions = getActionsForEmployee(isSigned).map(action => ({
                    ...action,
                    action: () => {
                        action.action(row.row.id); 
                    },
                    width: 'auto',
                    height: 'auto'
                }));

                return <DashboardActions actions={updatedActions} loading={row.loading} />
            }
        },
    ];

    const managerColumns = [
        { title: 'Ano', ref: 'year' },
        { title: 'Mês', ref: 'month' },
        { title: 'Tipo de holerite', ref: 'type' },
        { title: 'Data e hora da criação', ref: 'create_date' },
        { title: 'Usuário que importou', ref: 'importingUser' },
        {
            title: "", ref: "",
            renderCell: (row) => {
                console.log(" ~ DashboardListPaycheck ~ row:", row)
                const updatedActions = actionsSingle.map(action => ({
                    ...action,
                    action: () => {
                        action.action(row.row.uploadId);
                    }
                }));

                return (
                    <DashboardActions
                        actions={updatedActions}
                        loading={row.loading}
                        onClick={(e) => e.stopPropagation()}
                    />
                );
            }
        }
    ];

    const columns = user?.isManager ? managerColumns : employeeColumns;



    const parseRegisterOwner = (holerite, uploadId) => ({
        ...holerite,
        create_date: moment(holerite.createdAt).format("DD/MM/YYYY HH:mm"),
        type: typePaycheckOptions.find(option => option.id === holerite.type)?.title,
        sheetType: typeSheetOptions.find(option => option.id === holerite.sheetType)?.title,
        uploadId: uploadId,
        action: () => user?.isManager ? navigate(`dashboard/paycheck/form/${uploadId}`) : null
    });

    const parseRegister = item => {
        return {
            ...item,
            date: parseDatestringUSBR(item.createdAt),
            action: () => user?.isManager ? navigate(`dashboard/paycheck/form/${item.uploadId}`) : null,
            uploadId: item.uploadId,
            id: item.id,
            companyId: currentCompany?.id
        };
    };

    const init = async () => {
        setLoading(true);
        if (user?.isManager) {
            const holeriteResult = await ReadHoleritesGrouped(currentCompany?.id);

            if (holeriteResult) {
                const groupedByUploadId = holeriteResult.data.reduce((acc, group) => {
                    const { uploadId, holerites } = group;
                    acc[uploadId] = holerites.map(holerite => parseRegisterOwner(holerite, uploadId));
                    return acc;
                }, {});

                // Transform and sort the records
                const consolidatedHoleriteRegisters = Object.values(groupedByUploadId)
                    .flat()
                    .sort((a, b) => {
                        // Primeiro compara o ano
                        if (a.year !== b.year) {
                            return parseInt(b.year) - parseInt(a.year);
                        }
                        // Se o ano for igual, compara o mês
                        return parseInt(b.month) - parseInt(a.month);
                    });

                setRows(consolidatedHoleriteRegisters);
            }
        } else {
            const result = await ReadEmployeeByUserId(user?.id);
            const employeeId = result.data[0]?.id;
            const holeriteResult = await ReadAllForEmployee(employeeId);

            if (holeriteResult && !exposeStrapiError(holeriteResult)) {
                const normalHoleriteRegister = normalizeStrapiList(holeriteResult) || [];

                const consolidatedHoleriteRegisters = normalHoleriteRegister
                    .map(parseRegister)
                    .map(register => ({
                        ...register,
                        create_date: moment(register.createdAt).format("DD/MM/YYYY HH:mm"),
                        type: typePaycheckOptions.find(option => option.id === register.type)?.title,
                        sheetType: typeSheetOptions.find(option => option.id === register.sheetType)?.title,
                        reg: register.registration || register.data?.reg || '',
                        name: register.name || register.data?.nome || '',
                        cpf: register.cpf || register.data?.cpf || '',
                        liquid: register.data?.funcionarioResumo?.liquido || '',
                        signedAt: register.signedAt ? moment(register.signedAt).format("DD/MM/YYYY HH:mm") : 'Data e Hora não disponivel',
                        isSigned: Boolean(register.isSigned)
                    }))
                    .sort((a, b) => {
                        // Primeiro compara o ano
                        if (a.year !== b.year) {
                            return parseInt(b.year) - parseInt(a.year);
                        }
                        // Se o ano for igual, compara o mês
                        return parseInt(b.month) - parseInt(a.month);
                    });

                    

                setRows(consolidatedHoleriteRegisters);
            } else {
                console.error('Error fetching or normalizing holeriteResult');
                toast.error('Erro ao carregar os holerites');
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        setRows([]);
        if (currentCompany?.id) {
            init();
        }
    }, [currentCompany]);

    const filterRows = (rows, searchTerm) => {
        if (!searchTerm) return rows;
        
        const searchTermLower = searchTerm.toLowerCase().trim();
        console.log("🔍 Termo de busca:", searchTermLower);
        
        // Se o termo de busca for um ano (4 dígitos), fazemos uma correspondência exata
        if (/^\d{4}$/.test(searchTermLower)) {
            console.log("🎯 Buscando ano específico:", searchTermLower);
            const filtered = rows.filter(row => {
                const match = row.year?.toString() === searchTermLower;
                console.log(`Comparando: ${row.year} com ${searchTermLower} = ${match}`);
                return match;
            });
            console.log("📊 Resultados encontrados:", filtered.length);
            return filtered;
        }
        
        // Para outros casos, mantemos a busca parcial
        return rows.filter(row => {
            const matches = {
                year: row.year?.toString().includes(searchTermLower),
                month: row.month?.toString().padStart(2, '0').includes(searchTermLower),
                type: row.type?.toLowerCase().includes(searchTermLower),
                reg: row.registration?.toLowerCase().includes(searchTermLower),
                name: row.name?.toLowerCase().includes(searchTermLower),
                cpf: row.cpf?.toLowerCase().includes(searchTermLower),
                liquid: row.data?.funcionarioResumo?.liquido?.toLowerCase().includes(searchTermLower),
                signedAt: row.signedAt?.toLowerCase().includes(searchTermLower)
            };
            
            console.log("🔍 Verificando linha:", {
                id: row.id,
                matches
            });
            
            return Object.values(matches).some(match => match === true);
        });
    };

    // Adicione este useEffect para monitorar as mudanças nos dados filtrados
    useEffect(() => {
        console.log("💾 Dados originais:", rows.length);
        const filtered = filterRows(rows, searchExpression);
        console.log("🔄 Dados filtrados:", filtered.length);
        setFilteredRows(filtered);
    }, [rows, searchExpression]);

    // Adicione este log antes do return do JSX
    useEffect(() => {
        console.log(" Dados sendo passados para a tabela:", {
            filteredRows,
            searchExpression,
            columns
        });
    }, [filteredRows, searchExpression, columns]);

    return (
        <>
            <DashboardCardTabs title={"Holerites"} actions={actions} tabs={tabs} active={activeTab} setActive={setActiveTab} selectable={{ options, placeholder: "Ações" }}>
                {activeTab === 0 ? (
                    <>
                        {console.log("🎯 Renderizando tabela com:", filteredRows)}
                        <DashboardCardTable 
                            rows={filteredRows} 
                            actionsSingle={actionsSingle} 
                            columns={columns} 
                            searchExpression={searchExpression} 
                            pagination 
                            loading={loading}
                            key={searchExpression} // Força re-render quando a busca muda
                        />
                    </>
                ) : null}
            </DashboardCardTabs>
        </>
    );
}