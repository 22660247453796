import React from "react";  

import {  
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import DashboardListsUsers from "components/Dashboard/Lists/Users";

export default function DashboardListUsers(){  

    return ( 
        <>
            <ContainerAuthenticated page={"users"}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <DashboardListsUsers />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}