import React, { useContext, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { 
    
    FormActions, 
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import Drop from "components/Form/Drop";
import BooleanInput from "components/Form/BooleanInput";
import { Create, ReadAll, ReadOne, Update } from "services/requests";
import { exposeStrapiError, normalizeStrapiRegister, numerize, paycheckTypeOptions, statusOptions } from "utils";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { parseDatestringBRUS, parseDatestringUSBR } from "utils/parsers";
import { CoreContext } from "context/CoreContext";
import { ContentTableAction } from "ui/styled";
import Button from "components/Form/Button";
import moment from "moment";
import DashboardCardTable from "components/Dashboard/Cards/Table";


export default function DashboardFormsSolicitation(){   

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const { user, currentCompany, searchExpression } = useContext(CoreContext)

    const { id } = useParams()
    const refForm = useRef()
    const refFormResponse = useRef()

    console.log(user)

    const [ form, setForm ] = useState({
    })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState({
        type:"vacation",
        nome: user?.name,
        cpf: user?.cpf
    })
    const [saving, setSaving] = useState(false)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]

    const formItems = [
        { placeholder:"Nome do funcionário", ref:"nome", half:true, disabled:true },
        { placeholder:"CPF", ref:"cpf", half:true, mask:"999.999.999-99", disabled:true },
        { placeholder:"Tipo de solicitação", ref:"type", options:paycheckTypeOptions.slice(1,2), quarter:true },
        { placeholder:"Data de início", ref:"dateBegin", quarter:true, mask:"99/99/9999" },
        { placeholder:"Data final", ref:"dateEnd", quarter:true, mask:"99/99/9999" },
        { placeholder:"Qtd. de dias", ref:"days", quarter:true, mask:"999" },

        { custom:<BooleanInput title={"Pagar décimo terceiro?"} value={formValue('getYearly')} onChange={id ? null : e => changeForm(e, 'getYearly')}  />, half:true },
        { custom:<BooleanInput title={"Pagar abono?"} value={formValue('getAllowance')} onChange={id ? null : e => changeForm(e, 'getAllowance')}  />, half:true },

        { placeholder:"Obs. do funcionário", ref:"reason", type:"textarea", full:true },
    ]

    const formReviewItems = [
        { custom:<BooleanInput title={"Pagar décimo terceiro?"} value={formValue('isGetDecimoTerceiro')} onChange={e => changeForm(e, 'isGetDecimoTerceiro')} /> },
        { custom:<BooleanInput title={"Pagar abono?"} value={formValue('isGetAbono')} onChange={e => changeForm(e, 'isGetAbono')} /> },
        { custom:<BooleanInput title={"Aprovar solicitação?"} value={formValue('isApprove')} onChange={e => changeForm(e, 'isApprove')} /> },
        { placeholder:"Obs. do gerente", ref:"message", type:"textarea", full:true },
        { custom:<Drop title={"Adicionar arquivo"} file={formValue('file')} onChange={e => changeForm(e, 'file')}/> },
    ]


    const columns = [
        { title:'CPF', ref:'cpf' },
        { title:'Nome do funcionário', ref:'nome' },
        
        { title:'Data da solicitação', ref:'date' },
        
        { 
            title:'Status', 
            renderCell: ({ row }) => <>
                <ContentTableAction flat>
                    <Button color={row.status === 'Aprovado' ? 'green' : row.status === 'Recusado' ? 'red' : 'blue'} primary nospace small onClick={() => null}>
                        { row.status }
                    </Button>
                </ContentTableAction>
            </>
        },
        { title:'Data de pagamento', ref:'paymentDate' },
    ] 

    const [rows, setRows] = useState([ ])

    const init = async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
 
            const userRequests = await ReadAll(`filters[cpf]=${ normalResult?.cpf }`)
            if(userRequests?.data?.length){
                const usRqts = (userRequests?.data||[])?.filter(f => f?.attributes?.type === 'vacation').map(m => normalizeStrapiRegister(m))?.map( m => ({
                    ...m,
                    status: statusOptions.find(f => f.id === m.status)?.title,
                    date: moment(m.createdAt)?.format('L'),
                    paymentDate: moment(m.startDate)?.format('L'), 
                    action:() => navigate(`dashboard/solicitation/vacantion/${ m.id }`)
                }))
                setRows(usRqts)
                console.log('userRequests', usRqts )
            }


            setRegister({
                ...normalResult,
                dateBegin: normalResult?.startDate ? parseDatestringUSBR(normalResult.startDate) : "",
                dateEnd: normalResult?.startDate ? parseDatestringUSBR(normalResult.endDate) : "",
            })
            const nfile = normalResult?.answers?.[0]?.file?.data ? normalizeStrapiRegister(normalResult?.answers?.[0]?.file) : null
            setForm({ ...form, ...normalResult?.answers?.[0], getYearly: normalResult?.isGetDecimoTerceiro, getAllowance: normalResult?.isGetAbono, file: nfile })
        }
        setLoading(false)
    }

    const save = async () => {
        const nform = { ...refForm.current.getForm() }

        const answers = !id || !user?.isMAnager ? [] : [
            {
                ...refFormResponse.current.getForm(),
                ...form,
                file: form?.file?.id
            }
        ]
        
        const payload = {
            ...nform,
            startDate: parseDatestringBRUS(nform?.dateBegin),
            endDate:  parseDatestringBRUS(nform?.dateEnd),
            isGetDecimoTerceiro: form?.getYearly,
            isGetAbono: form?.getAllowance,
            company: currentCompany?.id,
            answers,
            user: user?.id
        }

        if(!valid(payload, formItems)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const valid = (payload, array) => {  
        for(let item of array){
            if(item?.ref && !payload?.[item?.ref]){
                return false;
            }
        }
        return true
    } 

    // const validateVacation = (value) => {
    //     console.log("BLUR", register, value)
    //     if( formValue(`dateBegin`) && formValue(`days`) ){
    //         // if( parseInt(formValue(`days`)) > parseInt(balance || "0") ){
    //         //     toast.error("Quantidade de dias maior que saldo");
    //         //     return;
    //         // }

    //         const bDates = formValue(`dateBegin`)?.split('/')
    //         const vacationBegins = new Date(bDates[2], bDates[1]-1, bDates[0])
    //         vacationBegins.setDate(vacationBegins.getDate() + parseInt(formValue(`days`)))
    //         const returnDate = `${numerize(vacationBegins.getDate())}/${numerize(vacationBegins.getMonth()+1)}/${vacationBegins.getFullYear()}`
            
    //         changeForm(returnDate, `dateEnd`)
    //     }
    // }
    
    useEffect(() => {
        if(id){
            init()
        }
    }, [id])


    return ( 
        <>
            <DashboardCardWrapper backable title={"Detalhe da solicitação"} white loading={saving}>
                {/* <DashboardFeedback message={"informe de rendimento cadastrado com sucesso"} type={"success"} /> */}
                <DashboardFormsCore formItems={formItems} ref={refForm} register={register} disabled={!!id} />
                {
                    !id || !user?.isManager ? null :
                    <DashboardCardWrapper title={"Responder a solicitação"} white>
                        <DashboardFormsCore formItems={formReviewItems} ref={refFormResponse} register={register?.answers?.[0]} />
                    </DashboardCardWrapper>
                }
                <DashboardCardTable title={"Timeline"} rows={rows} columns={columns} searchExpression={searchExpression} loading={loading} />
                <FormActions>
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions>                                
            </DashboardCardWrapper>
        </>
    );
}