import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/events`, params, true);
}

export const Read = async (id) => {
    return await GET(`/events?filters[company]=${id}&_limit=-1&populate=*&pagination[pageSize]=1000`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/events/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/events/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/events/${ id }`, true);
} 
 