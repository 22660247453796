import React, { useContext, useEffect, useState } from "react";  

import {

} from "./styled";


import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove } from "ui/styled";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, paycheckTypeOptions, statusOptions } from "utils";
import { Read } from "services/requests";
import { parseDatestringUSBR } from "utils/parsers";

export default function DashboardListsSolicitation(){  
    
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const [activeTab, setActiveTab] = useState(0)
    const { searchExpression, currentCompany, user } = useContext(CoreContext)

    const options = [
        { id:1, title:"Ver" }
    ]

    const tabs = [  ]

    const actions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        user?.isManager ? null : { title:'Solicitar férias', action:() => navigate(`dashboard/solicitation/vacantion`) },
        user?.isManager ? null : { title:'Solicitar reembolso', action:() => navigate(`dashboard/solicitation/refund`) }
    ].filter(f => f)

    const columns = [
        { title:'Nome do funcionário', ref:'nome' },
        { title:'CPF', ref:'cpf' },
        
        { title:'Tipo de solicitação', ref:'type' },
        // { title:'Data da solicitação', ref:'date' },
        { title:'Tem anexo?', ref:'hasAttach' },
        
        { 
            title:'Status', 
            renderCell: ({ row }) => <>
                <ContentTableAction flat>
                    <Button color={row.status === 'Aprovado' ? 'green' : row.status === 'Recusado' ? 'red' : 'blue'} primary nospace small onClick={() => null}>
                        { row.status }
                    </Button>
                </ContentTableAction>
            </>
        },
        // { title:'Data de pagamento', ref:'paymentDate' },
    ] 

    const [rows, setRows] = useState([ ])

    const [loading, setLoading] = useState(false)
    
    const parseRegister = item => ({ 
        ...item,
        type: paycheckTypeOptions.find(f => item.type === f.id)?.title || "",
        status: statusOptions.find(f => item.status === f.id)?.title || "",
        hasAttach: ( !!item?.answers?.[0]?.file?.data || !!item?.file?.data ) ? `Sim` : `Não`, 
        date: parseDatestringUSBR(item.createdAt), 
        paymentDate: parseDatestringUSBR(item.startDate), 
        action:() => navigate(`dashboard/solicitation/${ item.type === 'vacation' ? "vacantion" : "refund" }/${ item.id }`)
    })

    const init = async () => {
        setLoading(true)
        const result = await Read(currentCompany?.id)

        
        if(result && !exposeStrapiError(result)){
            const normalRegister = normalizeStrapiList(result) || []
            setRows(normalRegister?.filter( f => user?.isManager || f?.cpf === user?.cpf ).map(parseRegister))
        }
        setLoading(false)
    }
    

    useEffect(() => {
        if(currentCompany?.id){ init() ;}
    }, [currentCompany])

    return ( 
        <>
            <DashboardCardTabs title={"Solicitações"} actions={actions} tabs={tabs} active={activeTab} setActive={setActiveTab} selectable={{ options, placeholder:"Ações" }} >
                { activeTab === 0 ? <DashboardCardTable rows={rows} columns={columns} searchExpression={searchExpression} loading={loading} /> : null }
            </DashboardCardTabs>
        </>
    );
}