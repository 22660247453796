import { useContext, useEffect, useState } from "react";

import { } from "./styled";

import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { GeneratePdf, ReadAll, ReadAllByUploadId, ReadAllForEmployee } from "services/report-incomes";

import { pdf } from "@react-pdf/renderer";
import { IncomeStatement } from "components/GeneratePDF/IncomeStatement";
import moment from "moment";
import { parseDatestringUSBR } from "utils/parsers.js";
import { ReadEmployeeByUserId } from "services/employee.js";
import { toast } from "react-toastify";
import DashboardActions from "components/Dashboard/Actions";

export default function DashboardListsRevenues() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const [activeTab, setActiveTab] = useState(0);
  // const [pdfBlob, setPdfBlob] = useState(null);
  const { searchExpression, currentCompany, user } = useContext(CoreContext);

  const options = [{ id: 1, title: "Ver" }];

  const tabs = [
    { title: "Cadastros" },
    // { title:'Erros', badge:2 }
  ];

  const actions = [
    // { title:'Cancelar', outline:true, action:() => history.goBack() },
    ...(user?.isManager ? [{ title: 'Novo', action: () => navigate("dashboard/revenues/add") }] : [])
  ];

  const actionsSingle = [
    ...(user?.isManager ? [{
      title: "Download em LOTE",
      action: (uploadId) => downloadMassByUploadId(uploadId),
      loadable: true
    }] : []),
    ...(!user?.isManager ? [{
      title: "Download",
      action: (registerId) => downloadSingle(registerId),
      loadable: true
    }] : [])
  ];

  const downloadSingle = async (registerId) => {
    try {
      const blob = await GeneratePdf([registerId]);
      if (blob) {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `informe_${registerId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        throw new Error('Nenhum blob válido recebido para download.');
      }
    } catch (error) {
      console.error('Erro ao baixar o registro:', error);
      toast.error("Erro ao gerar o PDF: " + error.message);
    }
  };

  const columns = [
    { title: "Data e hora da criação", ref: "createdAt" },
    ...(user?.isManager ? [{ title: "Usuário que importou", ref: "importingUser" }] : []),
    { title: "Exercício do informe", ref: "exercise" },
    ...(user?.isManager ? [{
      title: "", ref: "",
      renderCell: (row) => {
        const updatedActions = actionsSingle.map(action => ({
          ...action,
          action: () => {
            action.action(row.row.uploadId); 
          }
        }));
        return <DashboardActions actions={updatedActions} loading={row.loading} />;
      }
    }] : [{
      title: "Nome", ref: "name"
    }, { title: "CPF", ref: "cpf" },
    {
      title: "", ref: "",
      renderCell: (row) => {
        const updatedActions = actionsSingle.map(action => ({
          ...action,
          action: () => {
            action.action(row.row.id); 
          }
        }));
        return <DashboardActions actions={updatedActions} loading={row.loading} />;
      }
    }
    ])
  ];

  const columnsImport = [
    { title: 'Data de importação', ref: 'dateImport' },
    { title: 'Usuario que importou', ref: 'importingUser' },
    { title: 'Enviado notificação', ref: 'notificationSent' },
    { title: 'Ano', ref: 'year' },
    { title: 'Data atualização', ref: 'updateDate' },
  ]

  const [rows, setRows] = useState([]);

  const columnsTab1 = [
    { title: "CPF", ref: "cpf" },
    { title: "Nome do funcionário", ref: "name" },
    { title: "Ano / Mês", ref: "date" },
    { title: "Tipo de holerite", ref: "type" },
    { title: "Erro", ref: "error" },
  ];

  const [rowsTab1, setRowsTab1] = useState([
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
  ]);

  // const generatePDF = async () => {
  //   const blob = await pdf(<IncomeStatement />).toBlob();
  //   setPdfBlob(blob);
  // };

  const [loading, setLoading] = useState(false);

  const parseRegister = item => {
    console.log('Item processado:', item);
    return {
      ...item,
      date: parseDatestringUSBR(item.createdAt),
      action: () => user?.isManager ? navigate(`dashboard/revenues/form/${item.uploadId}`) : null,
      uploadId: item.uploadId,
      id: item.id,
      companyId: currentCompany?.id
    };
  };

  const downloadMassByUploadId = async (uploadId) => {
    try {
      const result = await ReadAllByUploadId(uploadId);
      const ids = result.data.map(row => row.id);

      if (ids.length > 0) {
        const blob = await GeneratePdf(ids);
        if (blob) {
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `informes_${uploadId}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          throw new Error('Nenhum blob válido recebido para download.');
        }
      } else {
        toast.error("Nenhum registro encontrado para download.");
      }
    } catch (error) {
      console.error('Erro ao baixar os registros:', error);
      toast.error("Erro ao gerar o PDF: " + error.message);
    }
  };



  const init = async () => {
    setLoading(true);

    try {
      if (user?.isManager) {
        const incomeResult = await ReadAll(currentCompany?.id);
        // console.log('incomeResult', incomeResult)
        if (incomeResult && !exposeStrapiError(incomeResult)) {
          const normalIncomeRegister = normalizeStrapiList(incomeResult) || [];

          const groupedByUploadId = normalIncomeRegister.reduce((acc, item) => {
            if (!acc[item.uploadId]) {
              acc[item.uploadId] = item;
            }
            return acc;
          }, {});

          const consolidatedIncomeRegisters = Object.values(groupedByUploadId).map(parseRegister).map(register => {
            register.createdAt = moment(register.createdAt).format("DD/MM/YYYY HH:mm");
            return register;
          });

          setRows(consolidatedIncomeRegisters);
        }
      } else {
        const employee = await ReadEmployeeByUserId(user?.id);

        const employeeId = employee.data[0]?.id

        const incomeResult = await ReadAllForEmployee(employeeId);
        console.log('incomeResult', incomeResult)
        if (incomeResult && !exposeStrapiError(incomeResult)) {
          const normalIncomeRegister = normalizeStrapiList(incomeResult) || [];

          const consolidatedIncomeRegisters = Object.values(normalIncomeRegister).map(parseRegister).map(register => {
            console.log("🚀 ~ consolidatedIncomeRegisters ~ register:", register)
            register.createdAt = moment(register.createdAt).format("DD/MM/YYYY HH:mm");
            register.name = register.data?.nome || '';
            register.cpf = register.data?.cpf || '';
            return register;
          });

          setRows(consolidatedIncomeRegisters);
        }
      }
    } catch (error) {

    }

    setLoading(false);
  };

  useEffect(() => {
    if (currentCompany?.id) {
      init();
    }
  }, [currentCompany]);

  // useEffect(() => {
  //   generatePDF();
  // }, []);

  return (
    <>
      <DashboardCardTabs
        title={"Informes"}
        actions={actions}
        tabs={tabs}
        active={activeTab}
        setActive={setActiveTab}
        selectable={{ options, placeholder: "Ações" }}
      >
        {activeTab === 0 ? (
          <DashboardCardTable
            rows={rows}
            columns={columns}
            searchExpression={searchExpression}
            loading={loading}
          />
        ) : null}
        {activeTab === 1 ? (
          <DashboardCardTable
            rows={rowsTab1}
            columns={columnsTab1}
            searchExpression={searchExpression}
            loading={loading}
          />
        ) : null}
      </DashboardCardTabs>


      {/* <div>
        {pdfBlob && (
          <iframe
            src={URL.createObjectURL(pdfBlob)}
            width="100%"
            height="600px"
          />
        )}
      </div> */}
    </>
  );
}
