import React, { useEffect, useState } from "react";  

import { 
    DropPaycheckContent,
    DropPaycheck,
    DropPaycheckIcon,
    DropPaycheckName,
    DropPaycheckNameRemove,
    DropPaycheckPlace,
    DropPaycheckPlaceContainer
} from "./styled";
import UploadFile from "../UploadFile";
import { parseStrapiImage } from "utils";

export default function Drop({ title, file, onChange, disabled }){   

    const [currentFile, setCurrentFile] = useState(file)
    
    const downloadMode = (disabled ||  currentFile?.url) 
    const UPContent = !downloadMode ? UploadFile : DropPaycheckPlaceContainer
    
    const safeChange = value => {
        setCurrentFile(value)
        if(typeof onChange === "function"){
            onChange(value)
        }
    }

    const openFile = () => {
        if(currentFile?.url){
            window.open(
                parseStrapiImage(currentFile?.url)
            )
        }
    }

    const removeFile = () => {
        setCurrentFile(null)
    }

    useEffect(() => {
        setCurrentFile(file)
    }, [file])

    return ( 
        <>
            <UPContent onChange={downloadMode ? null : safeChange} onClick={!downloadMode ? null : openFile}>
                <DropPaycheckContent disabled={disabled}>
                    <DropPaycheck>
                        {
                            currentFile ? <DropPaycheckPlace image={parseStrapiImage(currentFile?.url)} /> : <>
                                <DropPaycheckIcon /> { title } 
                            </>
                        }
                    </DropPaycheck>
                    {
                        !currentFile ? null :
                        <DropPaycheckName>
                            { currentFile.name }
                            <DropPaycheckNameRemove onClick={removeFile} />
                        </DropPaycheckName>
                    }
                </DropPaycheckContent>
            </UPContent>
        </>
    );
}