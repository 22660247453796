import styled from 'styled-components'  

export const Content = styled.div.attrs({ 
})`
`;

export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
`;

export const RowReset = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
`;

export const RowResetText = styled.div.attrs({ 
})`
    margin: 12px 0 0;
    font-size: 14px;
    color: ${ props => props.theme.palette.colors.text };
`;


