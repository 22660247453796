import { useContext, useMemo, useState, useEffect, useRef } from "react";

import {

    CardHomeStatus,
    CardHomeStatuItem,
    CardHomeStatuItemStatus

} from "./styled";

import DashboardCardWrapper from "../Wrapper";
import { yearOptions, typePaycheckOptions, normalizeStrapiRegister } from "utils";
import { CoreContext } from "context/CoreContext";
import { parseCurrency } from "utils/parsers";

export default function DashboardCardHome({ register, filterYear, setFilterYear, filterType, setFilterType, filterMonth, setFilterMonth }) {
    const { user } = useContext(CoreContext);
    const [availableMonthOptions, setAvailableMonthOptions] = useState([]);
    const initialRegisterRef = useRef(null);

    const isDataLoaded = register && register.holeriteResult && register.holeriteResult.length > 0;

    useEffect(() => {
        if (register && !initialRegisterRef.current) {
            initialRegisterRef.current = register;
            
            const months = new Set();
            
            if (register?.holeriteMonthCounts) {
                register.holeriteMonthCounts.forEach((item, index) => {
                    if (item[1] > 0) {
                        const monthNumber = index + 1;
                        months.add(monthNumber);
                    }
                });
            }

            if (register?.employeeMonthCounts) {
                register.employeeMonthCounts.forEach(([_, count], index) => {
                    if (count > 0) {
                        const monthNumber = index + 1;
                        months.add(monthNumber);
                    }
                });
            }

            if (register?.holeriteSignatures) {
                register.holeriteSignatures.forEach(signature => {
                    if (signature.isSigned && signature.signedAt) {
                        const month = new Date(signature.signedAt).getMonth() + 1;
                        months.add(month);
                    }
                });
            }

            const monthOptions = Array.from(months)
                .sort((a, b) => a - b)
                .map(month => ({
                    id: String(month).padStart(2, '0'),
                    title: new Date(2024, month - 1).toLocaleString('pt-BR', { month: 'long' })
                }));

            setAvailableMonthOptions(monthOptions);
        }
    }, [register]);

    const proventos = isDataLoaded ? register.proventos || 0 : 0;
    const descontos = isDataLoaded ? register.descontoCounts?.reduce((acc, cur) => acc + cur[1], 0) || 0 : 0;
    const liquido = isDataLoaded ? register.holeriteMonthCounts?.reduce((acc, cur) => acc + cur[1], 0) || 0 : 0;

    const availableTypes = useMemo(() => {
        if (isDataLoaded) {
            const typesInRegister = [...new Set(register.holeriteResult.map(item => item.attributes.type))];
            return typePaycheckOptions.filter(option => typesInRegister.includes(option.id));
        }
        return [];
    }, [isDataLoaded, register]);

    const cards = user?.isManager ? (
        [
            ...(register?.events?.data || [])?.map(m => {
                const reg = normalizeStrapiRegister(m);
                return ({
                    title: reg?.name,
                    value: reg?.codes?.length
                });
            }),
            { title: "Solicitações", value: register?.totalRefund || 0 },
        ]
    ) : (
        [
            { title: "Proventos", value: parseCurrency(proventos) },
            { title: "Descontos", value: parseCurrency(descontos) },
            { title: "Líquido", value: parseCurrency(liquido) },
        ]
    );

    return (
        <>
            <DashboardCardWrapper
                title={user?.isManager ? "Dashboard - por quantidades de funcionários" : "Dashboard do Funcionário"}
                selectable={{
                    options: yearOptions,
                    placeholder: "Ano",
                    value: filterYear,
                    onChange: (value) => setFilterYear(value)
                }}
                selectableOptional={availableTypes.length > 0 ? {
                    options: availableTypes,
                    placeholder: "Tipo",
                    value: filterType,
                    onChange: (value) => setFilterType(value)
                } : null}
                selectableMonth={{
                    options: availableMonthOptions,
                    placeholder: "Mês",
                    value: filterMonth,
                    onChange: (value) => setFilterMonth(value)
                }}
            >
                <CardHomeStatus>
                    {cards.map((item, key) => (
                        <CardHomeStatuItem key={key}>
                            <b>{item.title}</b>
                            <p>{item.value}</p>
                            <CardHomeStatuItemStatus>
                                {user?.isManager ? "Em Desenvolvimento" : ""}
                            </CardHomeStatuItemStatus>
                        </CardHomeStatuItem>
                    ))}
                </CardHomeStatus>
            </DashboardCardWrapper>
        </>
    );
}






