import styled from 'styled-components'  

export const DropPaycheckContent = styled.div.attrs({ 
})`
    ${ props => props.disabled ? `opacity: .9` : `` }
`;

export const DropPaycheck = styled.div.attrs({ 
})`
    padding: 15px 12px;
    border-radius: 8px;
    border: 1px dashed ${ props => props.theme.palette.colors.shadow };

    display:flex;
    align-items: center;
    gap: 12px;

    cursor: pointer;
`;
export const DropPaycheckIcon = styled.img.attrs({ 
    src:'/icons/plus.svg'
})`
`;


export const DropPaycheckPlace = styled.div.attrs({ 
})`
    width:100%;
    height:90px;

    background: url(${ props => props.image }) no-repeat center center / cover;
`;

export const DropPaycheckName = styled.div.attrs({ 
})`
    font-size: 12px;
    display: flex;
    padding: 8px 4px;
    gap: 8px;
`;

export const DropPaycheckNameRemove = styled.img.attrs({ 
    src:'/icons/close.svg',
    width: 8
})`
    cursor: pointer;
`;



export const DropPaycheckPlaceContainer = styled.div.attrs({ 
})`
`;