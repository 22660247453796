import styled from 'styled-components'  

export const Content = styled.div.attrs({ 
})`
`;


export const SuccessMessage = styled.div.attrs({ 
})`
    margin: 24px 0 0;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${ props => props.theme.palette.success.border };
    background: ${ props => props.theme.palette.success.background };
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const SuccessMessageIcon = styled.img.attrs({ 
    src:'/icons/success.svg'
})`
`;
export const SuccessMessageClose = styled.img.attrs({ 
    src:'/icons/close.svg'
})`
    cursor:pointer;
    margin: 0 0 0 auto;
`;
export const SuccessMessageText = styled.div.attrs({ 
})`
    font-size: 16px;
    color: ${ props => props.theme.palette.colors.text };
`;




export const ErrorMessage = styled.div.attrs({ 
})`
    margin: 24px 0 0;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${ props => props.theme.palette.error.border };
    background: ${ props => props.theme.palette.error.background };
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const ErrorMessageIcon = styled.img.attrs({ 
    src:'/icons/error.svg'
})`
`;
export const ErrorMessageText = styled.div.attrs({ 
})`
    font-size: 16px;
    color: ${ props => props.theme.palette.colors.text };
`;
