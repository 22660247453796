import React, { useContext, useEffect } from "react";  

import { LookerContainer, LookerEmbed } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import { CoreContext } from "context/CoreContext";

export default function DashboardListLookerStudio(){  
    
    const { currentCompany } = useContext(CoreContext)

    const lookerUrl =  currentCompany?.urlLooker
    
    // const lookerUrl =  "https://lookerstudio.google.com/embed/reporting/f45d51e2-9b10-4205-aeef-9fefe75685b6/page/6zXD"
    return ( 
        <>
            <ContainerAuthenticated page={"looker"}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <LookerContainer>
                            <LookerEmbed src={lookerUrl} />
                        </LookerContainer>
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}