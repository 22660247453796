import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    DropPaycheck,
    DropPaycheckIcon,
    FormActions,
    ListEmployees,
    UnregisteredEmployeesList,
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";

import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import Drop from "components/Form/Drop";
import { toast } from "react-toastify";
import { capitalize, exposeStrapiError, normalizeStrapiRegister, numerize, yearOptions } from "utils";
import UploadFile from "components/Form/UploadFile";
import moment from "moment/moment";
import { Create, CreateAllImports, ReadRIncomes, Update } from "services/report-incomes";
import { UploadImage } from "services/api";
import { ReadEmployee } from "services/employee";
import { CoreContext } from "context/CoreContext";
import useEmployee from "hooks/useEmployee";
import { ConfirmationDialog } from "components/Modal";

export default function DashboardFormsRevenueAdd() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const refForm = useRef()
    const { currentCompany, user } = useContext(CoreContext)
    const { createEmployee } = useEmployee()

    const actions = [
        { title: 'Cancelar', outline: true, action: () => history.goBack() },
        { title: 'Salvar', action: () => save(), loadable: true }
    ]

    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [feedback, setFeedback] = useState(null)
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [unregisteredEmployees, setUnregisteredEmployees] = useState([]);
    const [showReplaceModal, setShowReplaceModal] = useState(false);

    const [formItems, setFormItems] = useState([])

    // const formItems = [
    //     { placeholder:"Ano", ref:"year", options:[], half:true },
    //     { placeholder:"Informe de rendimento", ref:"type", custom:<Drop title={"Adicionar arquivo"} />, half:true },
    // ]

    const maskCPF = (cpf) => {
        const digitsOnly = cpf.replace(/\D/g, '');
        return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    const save = async (e) => {
        try {
            const values = refForm.current.getForm();
            if (!(values['year'])) {
                toast.error("Preencha todos os campos");
                return;
            }
            if (!selectedFile) {
                toast.error("Selecione o arquivo");
                return;
            }

            setLoading(true);

            const result = await UploadImage(selectedFile, {
                type: "informerendimento",
                payload: {
                    'CNPJ': `${currentCompany?.cnpj}`?.replace(/\.|\-|\//g, ''),
                    'Ano Calendario': `${values['year']}`
                },
                companyId: currentCompany?.id
            });

            if (result.error) {
                if (result.message?.includes("Holerite já existe com os dados fornecidos.")) {
                    setShowReplaceModal(true);
                }
                return;
            }

            if (result && result.status === 400) {
                toast.error("Erro ao processar o arquivo. Verifique se o ano esta correto e correspondem aos dados do arquivo.");
            } else if (!result.length) {
                toast.error("Erro ao processar o arquivo, verifique se os dados do arquivo estão corretos.");
            } else {
                toast.success("informe de rendimento processado e cadastrado com sucesso.");
                history.goBack();
            }
        } catch (error) {
            console.error('Error during save process:', error);
            toast.error("Ocorreu um erro inesperado durante o processo.");
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleReplaceConfirm = async () => {
        const values = refForm.current.getForm();

        if (!selectedFile || !values['year']) {
            toast.warn("Selecione um arquivo e preencha todos os campos necessários.");
            return;
        }

        setLoading(true);

        const result = await UploadImage(selectedFile, {
            type: "informerendimento",
            payload: {
                'CNPJ': `${currentCompany?.cnpj}`?.replace(/\.|\-|\//g, ''),
                'Ano Calendario': values['year']
            },
            companyId: currentCompany?.id
        });

        console.log('result', result)

        setLoading(false);
    };



    const ReplaceModalContent = () => (
        <>
            <p>Já existe um informe de rendimento lançado para este CPF e período. Deseja substituir o arquivo existente?</p>
            {selectedFile?.name ?
                <DropPaycheck onClick={() => setSelectedFile(null)}>{selectedFile?.name} <DropPaycheckIcon close /></DropPaycheck>
                :
                <UploadFile forwardRepo onChange={e => setSelectedFile(e)}>
                    <DropPaycheck><DropPaycheckIcon /> Adicionar arquivo </DropPaycheck>
                </UploadFile>}
        </>
    );

    const UnregisteredEmployeesContent = () => (
        <UnregisteredEmployeesList>
            {unregisteredEmployees.map((employee, index) => (
                <ListEmployees key={index}>
                    Nome: {employee.nome} <br />
                    CNPJ: {employee.cnpjCpf} <br />
                    CPF: {employee.cpf} <br />
                    Razão social: {employee.razaoSocial}
                </ListEmployees>
            ))}
        </UnregisteredEmployeesList>
    );

    useEffect(() => {
        setFormItems([
            { placeholder: "Ano", ref: "year", options: yearOptions, half: true },
            {
                placeholder: "Informe de rendimento",
                ref: "type",
                custom: selectedFile?.name ?
                    <DropPaycheck onClick={() => setSelectedFile(null)}>{selectedFile?.name} <DropPaycheckIcon close /></DropPaycheck>
                    :
                    <UploadFile forwardRepo onChange={e => setSelectedFile(e)}>
                        <DropPaycheck><DropPaycheckIcon /> Adicionar arquivo </DropPaycheck>
                    </UploadFile>,
                half: true
            }
        ])
    }, [selectedFile])

    return (
        <>
            <DashboardCardWrapper backable title={"Cadastrar informes"} white>
                {feedback ? <DashboardFeedback message={"Informes de rendimento cadastrados com sucesso"} type={"success"} /> : null}
                <DashboardFormsCore formItems={formItems} ref={refForm} />
                <FormActions>
                    <DashboardActions actions={actions} loading={loading} />
                </FormActions>
            </DashboardCardWrapper>

            <ConfirmationDialog
                open={isDialogOpen}
                title="Funcionários Não Cadastrados"
                message="Os seguintes funcionários não estão cadastrados e precisam de atenção:"
                onCancel={handleClose}
                BtnConfirm={false}
                children={<UnregisteredEmployeesContent />}
            />

            <ConfirmationDialog
                open={showReplaceModal}
                title="Substituir Informe de Rendimento"
                onCancel={() => setShowReplaceModal(false)}
                onConfirm={handleReplaceConfirm}
                children={<ReplaceModalContent />}
            />

        </>
    );
}