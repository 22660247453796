import { Chart } from 'react-google-charts';
import DashboardCardWrapper from '../Wrapper';
import { CardHomeChart } from './styled';
import { theme } from 'ui/theme-color';

export default function DashboardCardEvents({ register }) {
    const totalProventos = register?.holeriteMonthCounts?.reduce((acc, cur) => acc + cur[1], 0) || 0;
    const totalDescontos = register?.descontoCounts?.reduce((acc, cur) => acc + cur[1], 0) || 0;

    // Forçar o gráfico a exibir valores mesmo se não houver dados reais
    const data = [
        ['Categoria', 'Valor'],
        ['Proventos', totalProventos || 1],  // Valor mocado ou valor real
        ['Descontos', totalDescontos || 1],   // Valor mocado ou valor real
    ];

    const options = {
        pieSliceText: 'value',
        pieSliceTextStyle: {
            color: 'white',
        },
        colors: [theme.palette.primary.main, theme.palette.red.main],
        chartArea: {
            width: '100%',
            height: '100%',
        },
        tooltip: {
            trigger: 'both',
        },
        legend: { position: 'none' },
    };

    const formatOptions = {
        type: 'NumberFormat',
        column: 1,
        options: {
            prefix: 'R$ ',
            negativeColor: 'red',
            negativeParens: true,
            fractionDigits: 2,
            decimalSymbol: ',',
            groupingSymbol: '.'
        }
    };

    return (
        <DashboardCardWrapper title={"Proventos / Descontos"}>
            <CardHomeChart>
                <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width={'100%'}
                    height={'300px'}
                    formatters={[formatOptions]}
                />
            </CardHomeChart>
        </DashboardCardWrapper>
    );
}
