import styled from 'styled-components'

export const Content = styled.div.attrs({ 
})`

`;

export const LookerContainer = styled.div.attrs({ 
})`
    margin: -50px -30px -50px -30px;
    min-height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    background: ${ props => props.theme.palette.colors.white };
`;

export const LookerEmbed = styled.iframe.attrs({ 
    width: "100%",
    height: "100%",
    frameborder: "0",
    allowfullscreen: true
})`
    border:0;
    flex:1;
`;