import React, { useContext, useEffect, useReducer, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { 
    FormActions, HeaderStatus, HeaderStatusItem
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import { ContentTableAction } from "ui/styled";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import Button from "components/Form/Button";
import MultiInput from "components/Form/MultiInput";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, ReadOne, Update } from "services/vacations";
import { exposeStrapiError, normalizeStrapiRegister, numerize, statusOptions } from "utils";
import { parseDatestringBRUS, parseDatestringUSBR } from "utils/parsers";
import { Read, ReadAll } from "services/requests";
import moment from "moment";


export default function DashboardFormsVacantion(){   

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const { searchExpression, currentCompany } = useContext(CoreContext)

    const { id } = useParams()
    const refForm = useRef()

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]

    const day = { dateBegin:'', dateEnd:'', dateDays:'' }
    const [codes, setCodes] = useState([  ])

    const [formItems, setFormItems] = useState([
        { placeholder:"Nome do funcionário", ref:"name", full:true },
        { placeholder:"RG", ref:"rg", half:true },
        { placeholder:"CPF", ref:"cpf", half:true, mask:"999.999.999-99" }
    ])

    const columns = [
        { title:'CPF', ref:'cpf' },
        { title:'Nome do funcionário', ref:'nome' },
        
        { title:'Data da solicitação', ref:'date' },
        
        { 
            title:'Status', 
            renderCell: ({ row }) => <>
                <ContentTableAction flat>
                    <Button color={row.status === 'Aprovado' ? 'green' : row.status === 'Recusado' ? 'red' : 'blue'} primary nospace small onClick={() => null}>
                        { row.status }
                    </Button>
                </ContentTableAction>
            </>
        },
        { title:'Data de pagamento', ref:'paymentDate' },
    ] 

    const [rows, setRows] = useState([ ])


    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState(null)
    const [saving, setSaving] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
 
            const userRequests = await ReadAll(`filters[cpf]=${ normalResult?.cpf }`)
            if(userRequests?.data?.length){
                const usRqts = (userRequests?.data||[])?.filter(f => f?.attributes?.type === 'vacation').map(m => normalizeStrapiRegister(m))?.map( m => ({
                    ...m,
                    status: statusOptions.find(f => f.id === m.status)?.title,
                    date: moment(m.createdAt)?.format('L'),
                    paymentDate: moment(m.startDate)?.format('L'), 
                    action:() => navigate(`dashboard/solicitation/vacantion/${ m.id }`)
                }))
                setRows(usRqts)
                console.log('userRequests', usRqts )
            }

            setCodes(normalResult?.scheduling?.length ? normalResult?.scheduling?.map(m => ({dateBegin: parseDatestringUSBR(m.initial), dateEnd:  parseDatestringUSBR(m.end), dateDays:  m.days }) ) : [ day ])
            setRegister({
                ...normalResult,
                date: parseDatestringUSBR(normalResult?.date)
            })
        }
        setLoading(false)
    }

    const save = async () => {
        const form = refForm.current.getForm()

        const today = new Date()
        today.setFullYear(today.getFullYear()+1)

        const payload = {
            ...form,
            date: parseDatestringBRUS(form?.createdAt),
            availableDays: 30,
            usedDays: 0,
            balanceDays: 30,
            company: currentCompany?.id,
            limit:`${ today.getFullYear() }-${ numerize(today.getMonth() + 1) }-${ numerize(today.getDate()) }` ,
            scheduling: codes.map(m => ({ initial: parseDatestringBRUS(m.dateBegin), end: parseDatestringBRUS(m.dateEnd), days: m.dateDays }))
        }

        if(!valid(payload, formItems)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            // if( id || !result?.data?.id ){
                history.goBack()
            // } else {
            //     navigate(`dashboard/vacantion/add/${ result?.data?.id }`)
            // }
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const valid = (payload, array) => {  
        for(let item of array){
            if(!payload?.[item?.ref]){
                return false;
            }
        }
        return true
    } 
    
    useEffect(() => {
        if(id){
            init()
        }
    }, [id])

    

    return ( 
        <>
            <DashboardCardWrapper backable title={"Agendar férias do funcionário"} white actions={actions} loading={saving}>
                {/* <DashboardFeedback message={"informe de rendimento cadastrado com sucesso"} type={"success"} /> */}
                <DashboardFormsCore disabled={!!id} formItems={formItems} register={register} ref={refForm} />
                {
                    !id || !register ? null : <>
                        <DashboardCardWrapper title={"Agendamento de férias do funcionário"} white custom={
                            <>
                                <HeaderStatus>
                                    {
                                        [
                                            { title:"Dias disponíveis", value:`${ register?.availableDays } dias` },
                                            { title:"Dias utilizados", value:`${ register?.usedDays }` },
                                            { title:"Saldo atualizado", value:`${ register?.balanceDays }` },
                                            { title:"Data limite", value:`${ parseDatestringUSBR(register?.limit) }` },
                                        ].map((item, key) => 
                                            <HeaderStatusItem key={key} limited={item.limited}>
                                                <b>{ item.title }</b>
                                                <p>{ item.value }</p>
                                            </HeaderStatusItem>
                                        )
                                    }
                                </HeaderStatus>
                            </>
                        } >
                            { codes.length ? <MultiInput period balance={register?.balanceDays} label={"Adicionar novo período"} value={codes} onChange={setCodes} /> : null}
                        </DashboardCardWrapper>
                        <DashboardCardTable title={"Solicitações do funcionário"} rows={rows} columns={columns} searchExpression={searchExpression} loading={loading} />
                    </>
                }
                <FormActions>
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions>                                
            </DashboardCardWrapper>
        </>
    );
}