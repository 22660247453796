import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/requests`, params, true);
}

export const Read = async (id) => {
    return await GET(`/requests?filters[company]=${id}&_limit=-1&pagination[pageSize]=1000&populate[0]=answers.file`, true);
}

export const ReadAll = async (query='') => {
    return await GET(`/requests?${query}&_limit=-1&pagination[pageSize]=1000&populate[0]=answers.file`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/requests/${id}?populate[0]=file&populate[1]=answers.file`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/requests/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/requests/${ id }`, true);
} 
 