import React, { useContext, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { 
    
    FormActions, 
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";

import MultiInput from "components/Form/MultiInput";
import { Create, ReadOne, Update } from "services/event";
import { booleanOptions, exposeStrapiError, normalizeStrapiRegister } from "utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";


export default function DashboardFormsEvent(){   

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const { id } = useParams()
    const { currentCompany } = useContext(CoreContext)
    
    const refForm = useRef()
    const [codes, setCodes] = useState(id ? [] : [' ']) 
    

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]

    const [formItems, setFormItems] = useState(id ?  [] : [
        { placeholder:"Evento", ref:"name", full:true },
        { placeholder:"Código do evento", ref:"codes", custom:<MultiInput label={"Adicionar novo código"} placeholder={"Código do evento"} value={codes} onChange={setCodes} />, full:true },
        { placeholder:"Mostrar no Dashboard", ref:"is_dashboard" },
    ])

    const [begging, setBegging] = useState(true)
    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState(null)
    const [saving, setSaving] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            setRegister({
                ...normalResult,
                is_dashboard: `${normalResult?.is_dashboard}`
            })
            setCodes(normalResult?.codes?.length ? normalResult?.codes?.map(m => m.code) : [' '])
            // console.log(normalResult?.codes?.length ? normalResult?.codes?.map(m => m.code) : [])
        }
        setLoading(false)
    }

    const save = async () => {

        const payload = {
            ...refForm.current.getForm(),
            company: currentCompany?.id,
            codes: codes.map(m => ({ code:m }))
        }

        // console.log("payload", payload)

        if(!valid(payload, formItems)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const valid = (payload, array) => {  
        for(let item of array){
            if(!payload?.[item?.ref]){
                return false;
            }
        }
        return true
    } 
    
    useEffect(() => {
        if(id){
            init()
        }
    }, [id])
    
    useEffect(() => {
        if( codes?.length && begging){
            setBegging(false)
            setFormItems([
                { placeholder:"Evento", ref:"name", full:true },
                { placeholder:"Código do evento", ref:"codes", custom:<MultiInput label={"Adicionar novo código"} placeholder={"Código do evento"} value={codes} onChange={setCodes} />, full:true },
                { placeholder:"Mostrar no Dashboard", ref:"is_dashboard", options:booleanOptions },
            ])
        }
    }, [codes])
    

    return ( 
        <>
            <DashboardCardWrapper backable title={"Novo evento"} white actions={actions} loading={saving}>
                {/* <DashboardFeedback message={"informe de rendimento cadastrado com sucesso"} type={"success"} /> */}
                <DashboardFormsCore formItems={formItems} register={register} ref={refForm} />
                <FormActions>
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions>                                
            </DashboardCardWrapper>
        </>
    );
}